import React from 'react'

const Privacy = () => {
    return (
        <div className='w-full flex justify-center items-center text-claim'>
            <div className='flex flex-col xl:w-topbar-xl md:w-topbar-md w-topbar-sm pl-[10px] pr-[10px]'>
                <div className='w-full font-bold text-xl text-claim border-t-[3px] border-t-site-lite-orange border-b-[3px] border-b-site-lite-orange mt-[10px] mb-[10px]'>
                    Cookie Policy di Immobiliare Carnazzola
                </div>
                <div className='text-sm text-justify mb-[10px]'>
                    I Cookie sono costituiti da porzioni di codice installate all'interno del browser che assistono il Titolare nell’erogazione del Servizio in base alle finalità descritte. Alcune delle finalità di installazione dei Cookie potrebbero, inoltre, necessitare del consenso dell'Utente.
                    Quando l’installazione di Cookies avviene sulla base del consenso, tale consenso può essere revocato liberamente in ogni momento seguendo le istruzioni contenute in questo documento.
                </div>
                <div className='text-xl font-bold mb-[10px] '>
                    Cookie Tecnici E Di Statistica Aggregata
                </div>
                <div className='text-base font-bold mb-[10px] '>
                    Attività strettamente necessarie al funzionamento
                </div>
                <div className='text-sm text-justify mb-[10px]'>
                    Immobiliare Carnazzola utilizza Cookie per salvare la sessione dell'Utente e per svolgere altre attività strettamente necessarie al funzionamento di Immobiliare Carnazzola, ad esempio in relazione alla distribuzione del traffico.
                </div>
                <div className='text-base font-bold mb-[10px] '>
                    Attività di salvataggio delle preferenze, ottimizzazione e statistica
                </div>
                <div className='text-sm text-justify mb-[10px]'>
                    Immobiliare Carnazzola utilizza Cookie per salvare le preferenze di navigazione ed ottimizzare l'esperienza di navigazione dell'Utente. Fra questi Cookie rientrano, ad esempio, quelli per impostare la lingua e la valuta o per la gestione di statistiche da parte del Titolare del sito.
                </div>
                <div className='text-xl font-bold mb-[10px] '>
                    Altre Tipologie Di Cookie O Strumenti Terzi Che Potrebbero Installarne
                </div>
                <div className='text-sm text-justify mb-[10px]'>
                    Alcuni dei servizi elencati di seguito raccolgono statistiche in forma aggregata ed anonima e potrebbero non richiedere il consenso dell'Utente o potrebbero essere gestiti direttamente dal Titolare – a seconda di quanto descritto – senza l'ausilio di terzi.
                </div>
                <div className='text-sm text-justify mb-[10px]'>
                    Qualora fra gli strumenti indicati in seguito fossero presenti servizi gestiti da terzi, questi potrebbero – in aggiunta a quanto specificato ed anche all’insaputa del Titolare – compiere attività di tracciamento dell’Utente. Per informazioni dettagliate in merito, si consiglia di consultare le privacy policy dei servizi elencati.
                </div>
                <div className='text-base font-bold mb-[10px] '>
                    Interazione con social network e piattaforme esterne
                </div>
                <div className='text-sm text-justify mb-[10px]'>
                    Questo tipo di servizi permette di effettuare interazioni con i social network, o con altre piattaforme esterne, direttamente dalle pagine di Immobiliare Carnazzola. Le interazioni e le informazioni acquisite da Immobiliare Carnazzola sono in ogni caso soggette alle impostazioni privacy dell’Utente relative ad ogni social network. Nel caso in cui sia installato un servizio di interazione con i social network, è possibile che, anche nel caso gli Utenti non utilizzino il servizio, lo stesso raccolga dati di traffico relativi alle pagine in cui è installato.
                    Pulsante Tweet e widget sociali di Twitter (Twitter, Inc.) Il pulsante Tweet e i widget sociali di Twitter sono servizi di interazione con il social network Twitter, forniti da Twitter, Inc. Dati Personali raccolti: Cookie e Dati di utilizzo.
                    Luogo del trattamento: Stati Uniti – Privacy Policy. Soggetto aderente al Privacy Shield.
                </div>
                <div className='text-base font-bold mb-[10px] '>
                    Ottimizzazione e distribuzione del traffico
                </div>
                <div className='text-sm text-justify mb-[10px]'>
                    Questo tipo di servizi permette a Immobiliare Carnazzola di distribuire i propri contenuti tramite dei server dislocati sul territorio e di ottimizzare le prestazioni della stessa. I Dati Personali trattati dipendono dalle caratteristiche e della modalità d’implementazione di questi servizi, che per loro natura filtrano le comunicazioni fra PImmobiliare Carnazzola ed il browser dell’Utente. Vista la natura distribuita di questo sistema, è difficile determinare i luoghi in cui vengono trasferiti i contenuti, che potrebbero contenere Dati Personali dell’Utente.
                </div>
                <div className='text-base font-bold mb-[10px] '>
                    Protezione dallo SPAM
                </div>
                <div className='text-sm text-justify mb-[10px]'>
                    I servizi contenuti nella presente sezione permettono al Titolare del Trattamento di monitorare e analizzare i dati di traffico e servono a tener traccia del comportamento dell’Utente.
                    Google Analytics con IP anonimizzato (Google Inc.) Google Analytics è un servizio di analisi web fornito da Google Inc. (“Google”). Google utilizza i Dati Personali raccolti allo scopo di tracciare ed esaminare l’utilizzo di Immobiliare Carnazzola, compilare report e condividerli con gli altri servizi sviluppati da Google. Google potrebbe utilizzare i Dati Personali per contestualizzare e personalizzare gli annunci del proprio network pubblicitario. Questa integrazione di Google Analytics rende anonimo il tuo indirizzo IP. L'anonimizzazione funziona abbreviando entro i confini degli stati membri dell'Unione Europea o in altri Paesi aderenti all'accordo sullo Spazio Economico Europeo l'indirizzo IP degli Utenti. Solo in casi eccezionali, l'indirizzo IP sarà inviato ai server di Google ed abbreviato all'interno degli Stati Uniti. Dati Personali raccolti: Cookie e Dati di utilizzo.
                    Luogo del trattamento: Stati Uniti – Privacy Policy – Opt Out. Soggetto aderente al Privacy Shield.
                </div>
                <div className='text-base font-bold mb-[10px] '>
                    Visualizzazione di contenuti da piattaforme esterne
                </div>
                <div className='text-sm text-justify mb-[10px]'>
                    Questo tipo di servizi permette di visualizzare contenuti ospitati su piattaforme esterne direttamente dalle pagine di Immobiliare Carnazzola e di interagire con essi. Nel caso in cui sia installato un servizio di questo tipo, è possibile che, anche nel caso gli Utenti non utilizzino il servizio, lo stesso raccolga dati di traffico relativi alle pagine in cui è installato.
                    Widget Video YouTube (Google Inc.) YouTube è un servizio di visualizzazione di contenuti video gestito da Google Inc. che permette a Immobiliare Carnazzola di integrare tali contenuti all’interno delle proprie pagine. Dati Personali raccolti: Cookie e Dati di utilizzo.
                    Luogo del trattamento: USA – Privacy Policy.
                </div>
                <div className='text-xl font-bold mb-[10px] '>
                    Come Posso Esprimere Il Consenso All'installazione Di Cookie?
                </div>
                <div className='text-sm text-justify mb-[10px]'>
                    In aggiunta a quanto indicato in questo documento, l'Utente può gestire le preferenze relative ai Cookie direttamente all'interno del proprio browser ed impedire – ad esempio – che terze parti possano installarne. Tramite le preferenze del browser è inoltre possibile eliminare i Cookie installati in passato, incluso il Cookie in cui venga eventualmente salvato il consenso all'installazione di Cookie da parte di questo sito. L'Utente può trovare informazioni su come gestire i Cookie con alcuni dei browser più diffusi ad esempio ai seguenti indirizzi: Google Chrome, Mozilla Firefox, Apple Safari e Microsoft Internet Explorer.
                    Con riferimento a Cookie installati da terze parti, l'Utente può inoltre gestire le proprie impostazioni e revocare il consenso visitando il relativo link di opt out (qualora disponibile), utilizzando gli strumenti descritti nella privacy policy della terza parte o contattando direttamente la stessa.
                    Fermo restando quanto precede, l’Utente può avvalersi delle informazioni fornite da EDAA (UE), Network Advertising Initiative (USA) e Digital Advertising Alliance (USA), DAAC (Canada), DDAI (Giappone) o altri servizi analoghi. Con questi servizi è possibile gestire le preferenze di tracciamento della maggior parte degli strumenti pubblicitari. Il Titolare, pertanto, consiglia agli Utenti di utilizzare tali risorse in aggiunta alle informazioni fornite dal presente documento.
                </div>
                <div className='text-xl font-bold mb-[10px] '>
                    Titolare Del Trattamento Dei Dati
                </div>
                <div className='text-sm text-justify mb-[10px]'>
                    Clara Carnazzola
                    Indirizzo email del Titolare: info@immobiliarecarnazzola.it
                    Dal momento che l'installazione di Cookie e di altri sistemi di tracciamento operata da terze parti tramite i servizi utilizzati all'interno di Immobiliare Carnazzola non può essere tecnicamente controllata dal Titolare, ogni riferimento specifico a Cookie e sistemi di tracciamento installati da terze parti è da considerarsi indicativo. Per ottenere informazioni complete, l’Utente è invitato a consultare la privacy policy degli eventuali servizi terzi elencati in questo documento.
                    Vista l'oggettiva complessità di identificazione delle tecnologie basate sui Cookie l'Utente è invitato a contattare il Titolare qualora volesse ricevere qualunque approfondimento relativo all'utilizzo dei Cookie stessi tramite Immobiliare Carnazzola.
                </div>
                <div className='text-xl font-bold mb-[10px] '>
                    Definizioni E Riferimenti Legali
                </div>
                <div className='text-base font-bold mb-[10px] '>
                    Dati Personali (o Dati)
                </div>
                <div className='text-sm text-justify mb-[10px]'>
                    Clara Carnazzola
                    Costituisce dato personale qualunque informazione che, direttamente o indirettamente, anche in collegamento con qualsiasi altra informazione, ivi compreso un numero di identificazione personale, renda identificata o identificabile una persona fisica.
                </div>
                <div className='text-base font-bold mb-[10px] '>
                    Dati di Utilizzo
                </div>
                <div className='text-sm text-justify mb-[10px]'>
                    Sono le informazioni raccolte automaticamente attraverso Immobiliare Carnazzola (anche da applicazioni di parti terze integrate in Immobiliare Carnazzola), tra cui: gli indirizzi IP o i nomi a dominio dei computer utilizzati dall’Utente che si connette con Pokémon Central, gli indirizzi in notazione URI (Uniform Resource Identifier), l’orario della richiesta, il metodo utilizzato nell’inoltrare la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta dal server (buon fine, errore, ecc.) il paese di provenienza, le caratteristiche del browser e del sistema operativo utilizzati dal visitatore, le varie connotazioni temporali della visita (ad esempio il tempo di permanenza su ciascuna pagina) e i dettagli relativi all’itinerario seguito all’interno dell’Applicazione, con particolare riferimento alla sequenza delle pagine consultate, ai parametri relativi al sistema operativo e all’ambiente informatico dell’Utente.
                </div>
                <div className='text-base font-bold mb-[10px] '>
                    Utente
                </div>
                <div className='text-sm text-justify mb-[10px]'>
                    L'individuo che utilizza Immobiliare Carnazzola che, salvo ove diversamente specificato, coincide con l'Interessato.
                </div>
                <div className='text-base font-bold mb-[10px] '>
                    Interessato
                </div>
                <div className='text-sm text-justify mb-[10px]'>
                    La persona fisica cui si riferiscono i Dati Personali.
                </div>
                <div className='text-base font-bold mb-[10px] '>
                    Responsabile del Trattamento (o Responsabile)
                </div>
                <div className='text-sm text-justify mb-[10px]'>
                    La persona fisica, giuridica, la pubblica amministrazione e qualsiasi altro ente che tratta dati personali per conto del Titolare, secondo quanto esposto nella presente privacy policy.
                </div>
                <div className='text-base font-bold mb-[10px] '>
                    Titolare del Trattamento (o Titolare)
                </div>
                <div className='text-sm text-justify mb-[10px]'>
                    La persona fisica o giuridica, l'autorità pubblica, il servizio o altro organismo che, singolarmente o insieme ad altri, determina le finalità e i mezzi del trattamento di dati personali e gli strumenti adottati, ivi comprese le misure di sicurezza relative al funzionamento ed alla fruizione di Immobiliare Carnazzola. Il Titolare del Trattamento, salvo quanto diversamente specificato, è il titolare di Immobiliare Carnazzola.
                </div>
                <div className='text-base font-bold mb-[10px] '>
                    Immobiliare Carnazzola (o questa Applicazione)
                </div>
                <div className='text-sm text-justify mb-[10px]'>
                    Lo strumento hardware o software mediante il quale sono raccolti e trattati i Dati Personali degli Utenti.
                </div>
                <div className='text-base font-bold mb-[10px] '>
                    Servizio
                </div>
                <div className='text-sm text-justify mb-[10px]'>
                    Il Servizio fornito da Immobiliare Carnazzola così come definito nei relativi termini (se presenti) su questo sito/applicazione.
                </div>
                <div className='text-base font-bold mb-[10px] '>
                    Unione Europea (o UE)
                </div>
                <div className='text-sm text-justify mb-[10px]'>
                    Salvo ove diversamente specificato, ogni riferimento all’Unione Europea contenuto in questo documento si intende esteso a tutti gli attuali stati membri dell’Unione Europea e dello Spazio Economico Europeo.
                </div>
                <div className='text-base font-bold mb-[10px] '>
                    Cookie
                </div>
                <div className='text-sm text-justify mb-[10px]'>
                    Piccola porzione di dati conservata all'interno del dispositivo dell'Utente.
                </div>
                <div className='text-base font-bold mb-[10px] '>
                    Riferimenti legali
                </div>
                <div className='text-sm text-justify mb-[10px]'>
                    La presente informativa privacy è redatta sulla base di molteplici ordinamenti legislativi, inclusi gli artt. 13 e 14 del Regolamento (UE) 2016/679.
                    Ove non diversamente specificato, questa informativa privacy riguarda esclusivamente Immobiliare Carnazzola.
                </div>
            </div>
        </div>
    )
}

export default Privacy