import { useContext, useState, useEffect } from "react";
import { FirestoreContext } from './FirestoreProvider';
import { isNullOrWhitespace } from './CommonFunction';

import Toast from './Toast';
import TypologyTreeChild from "./TypologyTreeChild";


const TypologyTreeParent = ({ item, index }) => {
    const { deleteParentTypology, updateParentTypology, retrieveTypologies, addSubTypology } = useContext(FirestoreContext);
    const [showToast, setShowToast] = useState(false);
    const [isError, setIsError] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [currentSubTypology, setCurrentSubTypology] = useState("");
    const [currentSubTypologyOrder, setCurrentSubTypologyOrder] = useState(0);
    const [currentTypology, setCurrentTypology] = useState("");
    const [currentTypologyOrder, setCurrentTypologyOrder] = useState(0);
    const [subTypologyList, setSubTypologyList] = useState([]);

    const deleteTypology = async (typologyID) => {
        console.log(typologyID);
        await deleteParentTypology(typologyID)
            .then((res) => {
                setShowToast(true);
                setIsError(false);
                setToastMessage("Tipologia cancellata con successo")
                retrieveTypologies();
            }).catch((err) => {
                setShowToast(true);
                setIsError(true);
                setToastMessage("Errore durante la cancellazione della tipologia.")
            });
    }

    const updateTypology = async (typologyID) => {
        await updateParentTypology(typologyID, item.value, item.order)
            .then((res) => {
                setShowToast(true);
                setIsError(false);
                setToastMessage("Tipologia modificata con successo")
                retrieveTypologies();
            }).catch((err) => {
                setShowToast(true);
                setIsError(true);
                setToastMessage("Errore durante la modifica della tipologia.")
            });
    }

    const onCloseToast = () => {
        setShowToast(false);
    }

    const onChangeSubTypology = (e) => {
        setCurrentSubTypology(e.target.value);
    }

    const onChangeSubTypologyOrder = (e) => {
        setCurrentSubTypologyOrder(e.target.value);
    }

    const onChangeTypology = (e) => {
        setCurrentTypology(e.target.value);
        item.value = e.target.value;
    }

    const onChangeTypologyOrder = (e) => {
        setCurrentTypologyOrder(Number(e.target.value));
        item.order = Number(e.target.value);
    }

    const addCurrentSubTypology = () => {
        if (isNullOrWhitespace(currentSubTypology)) {
            setShowToast(true);
            setIsError(true);
            setToastMessage("Nome sotto tipologia vuoto")
            retrieveTypologies();
        } else {
            addSubTypology(item, currentSubTypology, currentSubTypologyOrder).then((res) => {
                retrieveTypologies();
            }).catch((err) => {
                retrieveTypologies();
            });
            setCurrentSubTypology("");
            setCurrentSubTypologyOrder(0);
        }
    }

    useEffect(() => {
        if(item.children !== undefined && item.children !== null){
            const sorted = item.children.sort((a, b) => a.order - b.order);
            setSubTypologyList(sorted);
        }else{
            setSubTypologyList([]);
        }
    }, [item.children])


    return (
        <div key={index}>
            {showToast &&
                <Toast isError={isError} message={toastMessage} duration={30000} whenClose={onCloseToast} />
            }
            <div className="pb-[30px]">
                <div className="mx-auto bg-white rounded overflow-hidden shadow-lg w-full">
                    <div className="p-6">
                        <div className="mb-2 flex flex-row">
                            <input className="input-text w-[75%] mr-[10px]" type="text" value={item.value} onChange={onChangeTypology} placeholder="Aggiungi categoria" />
                            <input className="input-text w-[5%] mr-[10px]" type="number" value={item.order} min={0} max={1000} step={1} onChange={onChangeTypologyOrder} placeholder="Ordine" />
                            <button className='w-[10%] h-[35px] mr-[10px] btn-orange text-white font-bold pt-[5px] pb-[5px] pl-[10px] pr-[10px] rounded-[2px] text-[15px]' onClick={() => updateTypology(item.id)} >Modifica</button>
                            <button className='w-[10%] h-[35px] btn-orange text-white font-bold pt-[5px] pb-[5px] pl-[10px] pr-[10px] rounded-[2px] text-[15px]' onClick={() => deleteTypology(item.id)}>Elimina</button>
                        </div>
                        <div className="mb-2 flex flex-row ml-[20px]">
                            <input className="input-text w-[80%] mr-[10px]" type="text" value={currentSubTypology} onChange={onChangeSubTypology} placeholder="Aggiungi sotto-categoria" />
                            <input className="input-text w-[5%] mr-[5px]" type="number" min={0} value={currentSubTypologyOrder} placeholder="Ordine" onChange={onChangeSubTypologyOrder} />
                            <button className='w-[15%] h-[35px] ml-[5px] btn-orange text-white font-bold pt-[5px] pb-[5px] pl-[10px] pr-[10px] rounded-[2px] text-[15px]' onClick={addCurrentSubTypology}>Aggiungi</button>
                        </div>
                        {/* {(item.children !== null || item.children !== undefined) && item.children.map((child, index) => {
                            return(
                                <TypologyTreeChild parentID={item.key} item={child} index={index} />
                            )
                        })} */}
                        {subTypologyList !== undefined && subTypologyList.map((child, index) => {
                            return (
                                <TypologyTreeChild parentItem={item} item={child} index={index} key={index} />
                            )
                        })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TypologyTreeParent;