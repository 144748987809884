const AddImmobileFasciaLocazioneItem = ({ name, callbackRemoveLocazione }) => {

    const handleRemoveLocazione = () => {
        callbackRemoveLocazione(name)
    }

    return (
        <>
            <span className='bg-site-orange p-2 rounded-l-[5px] text-white h-fit font-bold'>{name}</span>
            <span className='bg-site-orange p-2 rounded-r-[5px] text-white h-fit mr-4 cursor-pointer' onClick={handleRemoveLocazione}><i className="text-white icofont-ui-close"></i></span>
        </>
    )
}

export default AddImmobileFasciaLocazioneItem;