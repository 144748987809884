import { useContext, useEffect, useState } from 'react'
import { FirestoreContext } from './FirestoreProvider';
import AddImmobile from './AddImmobile';
import { BASE_LINK, BOX1, BOX2 } from './CommonFunction';
import copy from 'copy-to-clipboard';

const ImmobiliItem = ({ callbackRealod, setMessage, building }) => {
    const baseBtnClasses = " text-white font-bold mt-[5px] mb-[5px] pt-[2px] pb-[2px] pl-[5px] pr-[5px] rounded-[2px] text-[15px]";
    const btnGreen = "btn-green" + baseBtnClasses;
    const btnGrey = "btn-grey" + baseBtnClasses;

    const { deleteBuilding, updateBuildingCustomBox } = useContext(FirestoreContext);
    const [showAdd, setShowAdd] = useState(false);
    const [coverImage, setCoverImage] = useState("");


    const showAddImmobile = () => {
        setShowAdd(!showAdd)
        // callbackRealod();
    }

    const handleEditBuilding = () => {
        showAddImmobile();
    }

    const handleDeleteBuilding = async () => {
        console.log(building);
        if (!!building.images && building.images.length > 0) {
            setMessage(true, "Ci sono delle immagini associate a questo annuncio, prima di cancellarlo, rimuovere le immagini")
        } else {
            await deleteBuilding(building.id)
                .then(() => {
                    setMessage(false, "Annuncio cancellato con successo")
                    callbackRealod()
                })
                .catch((err) => {
                    setMessage(true, "Errore durante la cancellazione dell'annuncio. Riprovare più tardi")
                })
        }
    }

    const setBox1 = async () => {
        if (building.custombox === BOX1) {
            building.custombox = null;
        } else {
            building.custombox = BOX1;
        }

        updateCustomBox();
    }

    const setBox2 = () => {
        if (building.custombox === BOX2) {
            building.custombox = null;
        } else {
            building.custombox = BOX2;
        }

        updateCustomBox();
    }

    const updateCustomBox = async () => {
        await updateBuildingCustomBox(building.id, building.custombox)
            .then(() => {
                setMessage(false, "Box associato con successo");
                callbackRealod();
            })
            .catch((err) => {
                setMessage(true, "Errore durante l'associazione del box");
            });
    }

    const copyLink = () => {
        copy(BASE_LINK + building.idAnnuncio);
        setMessage(false, "Link annuncio copiato con successo");
    }

    useEffect(() => {
        if (!!building.images && building.images.length > 0) {
            var image = building.images.filter(img => img.cover === true);
            if (!!image && image.length > 0) {
                setCoverImage(image[0])
            } else {
                setCoverImage(building.images[0]);
            }
        }
        if (building.tipologia.value === null || building.tipologia.value === undefined) {
            building.tipologia.value = [];
        }
    }, [coverImage])

    return (
        <tr key={building.id} className='text-base'>
            <td className='border border-site-light-grey3 p-[10px]'><img className='w-[100px] h-[70px]' alt='' src={coverImage.url} /></td>
            <td className='border border-site-light-grey3 p-[10px] w-[300px]'>
                <div className='w-full flex flex-col'>
                    <div className='mb-3 flex flex-row w-full'>
                        <div className="w-2/5 font-bold">Riferimento:</div>
                        <div className="w-3/5">{building.idAnnuncio}</div>
                    </div>
                    <div className='mb-3 flex flex-row w-full'>
                        <div className="w-2/5 font-bold">Contratto:</div>
                        <div className="w-3/5">{building.contract.value}</div>
                    </div>
                    <div className='mb-3 flex flex-row w-full'>
                        <div className="w-2/5 font-bold">Città:</div>
                        <div className="w-3/5">{building.city.value}</div>
                    </div>
                    <div className='mb-3 flex flex-row w-full'>
                        <div className="w-2/5 font-bold">Tipologie:</div>
                        <div className="w-3/5">
                            {building.tipologia.value.map((tipo, index) => {
                                return (
                                    <div className='mb-2' key={index}>
                                        {tipo}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </td>
            <td className='border border-site-light-grey3 p-[10px]'>{building.descrizione.value}</td>
            <td className='border border-site-light-grey3 p-[10px]'>
                <div className='flex flex-col'>
                    <button className='btn-orange text-white font-bold mt-[5px] mb-[5px] pt-[2px] pb-[2px] pl-[5px] pr-[5px] rounded-[2px] text-[15px]' onClick={handleEditBuilding}>MODIFICA</button>
                    <button className='btn-orange text-white font-bold mt-[5px] mb-[5px] pt-[2px] pb-[2px] pl-[5px] pr-[5px] rounded-[2px] text-[15px]' onClick={handleDeleteBuilding}>ELIMINA</button>
                    <button className='btn-orange text-white font-bold mt-[5px] mb-[5px] pt-[2px] pb-[2px] pl-[5px] pr-[5px] rounded-[2px] text-[15px]' onClick={copyLink}>LINK</button>

                    <button className={(building.custombox === null || building.custombox === undefined) ? btnGrey : (building.custombox === BOX1) ? btnGreen : btnGrey} onClick={setBox1}>BOX1</button>
                    <button className={(building.custombox === null || building.custombox === undefined) ? btnGrey : (building.custombox === BOX2) ? btnGreen : btnGrey} onClick={setBox2}>BOX2</button>
                    {showAdd &&
                        <AddImmobile showAddImmobile={showAddImmobile} reloadBuilding={callbackRealod} newItem={false} editBuilding={building} />
                    }
                </div>
            </td>
        </tr>
    );
};

export default ImmobiliItem;