import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from '../AuthProvider';

const Login = () => {
    const [showError, setShowError] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const { loginUser, loading } = useContext(AuthContext);
    const navigate = useNavigate();

    let email = "";
    let password = "";

    useEffect(() => {
        setShowError(false);
    }, [])

    const HandleLogin = () => {
        loginUser(email, password)
            .then((result) => {
                navigate("/immobili");
            })
            .catch((error) => {
                switch (error.code) {
                    case 'auth/invalid-email':
                        setErrorMessage("Email o password non valide");
                        break;
                    case 'auth/missing-password':
                        setErrorMessage("Password vuota")
                        break;
                    default:
                        setErrorMessage("Errore imprevisto durante la login, riprovare");
                        break;
                }
                setShowError(true);
            });
    }

    const ShowError = () => {
        setShowError(!showError);
    }

    return (
        <div className='w-full flex justify-center items-center text-claim'>
            {
                loading && <div className="overlay"><div className="spinner"></div></div>
            }
            {showError && (
                <div id="error-toast" className="fixed bottom-20 right-50 mb-4 mr-4 bg-red-500 text-white p-4 rounded-md" onClick={ShowError}>
                    {errorMessage}
                </div>
            )}
            <div className='flex flex-col w-topbar-sm pl-[10px] pr-[10px]'>
                <div className='w-full font-bold text-xl text-claim border-t-[3px] border-t-site-lite-orange border-b-[3px] border-b-site-lite-orange mt-[10px] mb-[10px]'>
                    Login
                </div>
                <div className='text-lg text-justify mb-[10px]'>
                    Username
                </div>
                <div className='text-justify mb-[10px]'>
                    <input type='email' className='w-full border border-gray-500 p-[5px]' placeholder='email' onChange={e => email = e.target.value} />
                </div>
                <div className='text-lg text-justify mb-[10px]'>
                    Password
                </div>
                <div className='text-justify mb-[10px]'>
                    <input type='password' className='w-full border border-gray-500 p-[5px]' placeholder='password' onChange={e => password = e.target.value} />
                </div>
                <div className='mb-[10px]'>
                    <span onClick={HandleLogin} className='cursor-pointer btn-orange text-white font-bold pt-[5px] pb-[5px] pl-[10px] pr-[10px] rounded-[2px] text-[15px]'>LOGIN</span>
                </div>
            </div>
        </div>
    )
}

export default Login