import { useContext, useEffect, useState } from "react";
import { FirestoreContext } from "./FirestoreProvider";
import MenuAdminDesktop from "./MenuAdminDesktop";
import BoxItem from "./BoxItem";
import Toast from "./Toast";

const Box = () => {

    const { boxList, getBox } = useContext(FirestoreContext);
    const [isError, setIsError] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);

    const callbackShowToast = (error, message) => {
        setMessage(error, message);
    }

    const setMessage = (isError, message) => {
        setToastMessage(message);
        setIsError(isError);
        setShowToast(true);
    }

    const onCloseToast = () => {
        setShowToast(false);
    }

    useEffect(() => {
        getBox();
    }, [])

    return (
        <>
            {showToast &&
                <Toast isError={isError} message={toastMessage} duration={3000} whenClose={onCloseToast} />
            }
            <MenuAdminDesktop />
            <div className="flex flex-col items-center justify-center">
                <div className='w-xl font-bold text-xl text-claim text-center border-t-[3px] border-t-site-lite-orange border-b-[3px] border-b-site-lite-orange mt-[10px] mb-[10px]'>
                    Box
                </div>
                <div className="pb-[30px]">
                    {boxList.map((b) => {
                        return (
                            <BoxItem key={b.key} box={b} callbackShowToast={callbackShowToast}/>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default Box;