import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';

const Toast = ({ isError, message, duration, whenClose }) => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setIsVisible(false);
            whenClose();
        }, duration);

        // Cleanup the timeout when the component is unmounted
        return () => clearTimeout(timeoutId);
    }, [duration, whenClose]);

    return isVisible ? (
        isError
            ?
            <div className="fixed top-[10%] left-[35%] w-[25%] right-0 mb-4 mr-4 bg-red-600 text-white p-4 rounded-md z-2000">
                {message}
            </div>
            :
            <div className="fixed top-[10%] left-[35%] w-[25%] right-0 mb-4 mr-4 bg-green-600 text-white p-4 rounded-md z-2000">
                {message}
            </div>
    ) : null;
};


Toast.propTypes = {
    isError: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    whenClose: PropTypes.func.isRequired,
};

export default Toast;