import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyCOb9k7rGU5vKJbSzmY98rTAFySmPmLGKE",
    authDomain: "immobiliare-carnazzola.firebaseapp.com",
    projectId: "immobiliare-carnazzola",
    storageBucket: "immobiliare-carnazzola.appspot.com",
    messagingSenderId: "125274197015",
    appId: "1:125274197015:web:99c2b2fa5e908f83bd2052"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app, 'gs://immobiliare-carnazzola.appspot.com');