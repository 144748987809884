import React, { useContext, useEffect } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { FirestoreContext } from './FirestoreProvider'
import { isNullOrWhitespace, LS_CONTRACT, LS_LEASERANGE, LS_LOCATION, LS_PRICERANGE, LS_TIPOLOGY } from './CommonFunction'



const Search = () => {
    const DEFAULT_LOCATION = "defaultLocation";
    const DEFAULT_CONTRACT = "defaultContract";
    const DEFAULT_TIPOLOGY = "defaultTipologia";
    const DEFAULT_PRICE = "defaultPrice";
    const DEFAULT_LEASE = "defaultLease";

    const { getRemoteLocations, getPriceRange, getLeaseRange, retrieveContracts, getBuildingsSearch } = useContext(FirestoreContext);
    const { flattenTypologies, retrieveFlattenTypologies } = useContext(FirestoreContext);

    const [currentLocation, setCurrentLocation] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState("");
    const [currentContract, setCurrentContract] = useState(null);
    const [selectedContract, setSelectedContract] = useState("");
    const [selectedTipology, setSelectedTipology] = useState("");
    const [currentPriceRange, setCurrentPriceRange] = useState(null);
    const [selectedPriceRange, setSelectedPriceRange] = useState("");
    const [currentLeaseRange, setCurrentLeaseRange] = useState(null);
    const [selectedLeaseRange, setSelectedLeaseRange] = useState("");
    const [isSearchPage, setIsSearchPage] = useState(false);

    useEffect(() => {
        retrieveFlattenTypologies();
        getRemoteLocations()
            .then((res) => {
                setCurrentLocation([...res]);
            })
        getContract();
        internalGetPriceRange();
        internalGetLeaseRange();
    }, [])

    const getContract = () => {
        let contract = retrieveContracts();
        setCurrentContract(contract);
    }

    const internalGetPriceRange = () => {
        let priceRange = getPriceRange();
        setCurrentPriceRange(priceRange);
    }

    const internalGetLeaseRange = () => {
        let leaseRange = getLeaseRange();
        setCurrentLeaseRange(leaseRange);
    }

    const handleLocationChange = (e) => {
        setSelectedLocation(e.target.value);
    }

    const handleContractChange = (e) => {
        setSelectedContract(e.target.value);
        setSelectedLeaseRange("");
        setSelectedPriceRange("");
    }

    const handleTipologyChange = (e) => {
        setSelectedTipology(e.target.value);
    }

    const handlePriceRangeChange = (e) => {
        setSelectedPriceRange(e.target.value);
    }

    const handleLeaseRangeChange = (e) => {
        setSelectedLeaseRange(e.target.value);
    }

    return (
        <div className='flex flex-col w-full justify-center'>
            <div className='font-bold text-2xl text-claim border-t-[3px] border-t-site-lite-orange border-b-[3px] border-b-site-lite-orange m-[10px]'>
                Cerca la tua casa
            </div>
            <div className='flex flex-col m-[10px] mb-[5px]'>
                {
                    currentLocation !== null &&
                    <select className='select' onChange={handleLocationChange} value={selectedLocation}>
                        <option key={DEFAULT_LOCATION} value={DEFAULT_LOCATION}>Località</option>
                        {currentLocation.map((location, key) => {
                            return (
                                <option key={key} value={location.value}>{location.value}</option>
                            )
                        })}
                    </select>
                }
            </div>
            <div className='flex flex-col m-[10px] mt-[5px] mb-[5px]'>
                {
                    currentContract !== null &&
                    <select className='select' onChange={handleContractChange} value={selectedContract}>
                        <option key={DEFAULT_CONTRACT} value={DEFAULT_CONTRACT}>Contratto</option>
                        {currentContract.map((contract, key) => {
                            return (
                                <option key={key} value={contract.value}>{contract.value}</option>
                            )
                        })}
                    </select>
                }
            </div>
            <div className='flex flex-col m-[10px] mt-[5px] mb-[5px]'>
                {
                    flattenTypologies !== null &&
                    <select className='select' onChange={handleTipologyChange} value={selectedTipology}>
                        <option key={DEFAULT_TIPOLOGY} value={DEFAULT_TIPOLOGY}>Tipologia</option>
                        {flattenTypologies.flat().map((typology, key) => {
                            return (
                                <option key={key} value={typology.value}>{typology.value}</option>
                            )
                        })}
                    </select>
                }
            </div>
            {
                selectedContract === "Vendita" &&
                <div className='flex flex-col m-[10px] mt-[5px] mb-[5px]'>
                    {
                        currentPriceRange !== null &&
                        <select className='select' onChange={handlePriceRangeChange} value={selectedPriceRange}>
                            <option key={DEFAULT_PRICE} value={DEFAULT_PRICE}>Fascia di prezzo</option>
                            {currentPriceRange.map((price, key) => {
                                return (
                                    <option key={key} value={price.value}>{price.value}</option>
                                )
                            })}
                        </select>
                    }
                </div>
            }
            {
                selectedContract === "Locazione" &&
                <div className='flex flex-col m-[10px] mt-[5px] mb-[5px]'>
                    {
                        currentLeaseRange !== null &&
                        <select className='select' onChange={handleLeaseRangeChange} value={selectedLeaseRange}>
                            <option key={DEFAULT_LEASE} value={DEFAULT_LEASE}>Periodo di locazione</option>
                            {currentLeaseRange.map((lease, key) => {
                                return (
                                    <option key={key} value={lease.value}>{lease.value}</option>
                                )
                            })}
                        </select>
                    }
                </div>
            }
            <div className='h-auto m-[10px]'>
                <Link to='/cerca' className='btn-orange text-white font-bold pt-[5px] pb-[5px] pl-[10px] pr-[10px] rounded-[2px] text-[15px]'
                    state={{ localita: selectedLocation, contract: selectedContract, tipology: selectedTipology, price: selectedPriceRange, lease: selectedLeaseRange, runSearch: true, itemList:[] }}
                >
                    CERCA
                </Link>

            </div>
        </div >
    )
}

export default Search