import { useEffect, useState, useContext } from "react";
import { FirestoreContext } from './FirestoreProvider';

import Toast from './Toast';
import { isNullOrWhitespace } from "./CommonFunction";

const TypologyTreeChild = ({ parentItem, item, index }) => {
    const { deleteSubTypology, retrieveTypologies, updateChildTypology } = useContext(FirestoreContext);
    const [showToast, setShowToast] = useState(false);
    const [isError, setIsError] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [currentTypology, setCurrentTypology] = useState("");
    const [currentTypologyOrder, setCurrentTypologyOrder] = useState(0);

    const onCloseToast = () => {
        setShowToast(false);
    }

    const onChangeCurrentTypology = (e) => {
        item.value = e.target.value;
        setCurrentTypology(e.target.value);
    }

    const onChangeCurretTypologyOrder = (e) => {
        item.order = Number(e.target.value);
        setCurrentTypologyOrder(Number(e.target.value));
    }

    useEffect(() => {
        setCurrentTypology(item.value);
    }, []);

    const handleDelete = () => {
        deleteSubTypology(parentItem, item)
            .then((res) => {
                setShowToast(true);
                setIsError(false);
                setToastMessage("Sotto tipologia rimossa con successo")
                retrieveTypologies();
            })
            .catch((err) => {
                setShowToast(true);
                setIsError(true);
                setToastMessage("Errore durante la cancellazione della sotto tipologia")
                retrieveTypologies();
            });
    }

    const handleUpdate = () => {
        if (isNullOrWhitespace(item.value)) {
            setShowToast(true);
            setIsError(true);
            setToastMessage("Sotto tipologia vuota")
        } else {
            updateChildTypology(parentItem).then((res) => {
                setShowToast(true);
                setIsError(false);
                setToastMessage("Sotto tipologia modificato con successo")
                retrieveTypologies();
            }).catch((err) => {
                setShowToast(true);
                setIsError(true);
                setToastMessage("Errore durante la modifica della sotto tipologia")
                retrieveTypologies();
            });
        }
    }


    return (
        <div key={index}>
            {showToast &&
                <Toast isError={isError} message={toastMessage} duration={3000} whenClose={onCloseToast} />
            }
            <div className="mb-2 flex flex-row ml-[20px]" key={index}>
                <input className="input-text w-[75%] mr-[10px]" type="text" value={item.value} onChange={onChangeCurrentTypology} placeholder="Aggiungi sotto-categoria" />
                <input className="input-text w-[5%] mr-[10px]" type="number" value={item.order} min={0} max={1000} step={1} onChange={onChangeCurretTypologyOrder} placeholder="Ordine" />
                <button className='w-[10%] h-[35px] mr-[10px] btn-orange text-white font-bold pt-[5px] pb-[5px] pl-[10px] pr-[10px] rounded-[2px] text-[15px]' onClick={handleUpdate}>Modifica</button>
                <button className='w-[10%] h-[35px] btn-orange text-white font-bold pt-[5px] pb-[5px] pl-[10px] pr-[10px] rounded-[2px] text-[15px]' onClick={handleDelete}>Elimina</button>
            </div>
        </div>
    );

}

export default TypologyTreeChild;