import {
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signOut,
} from "firebase/auth";
import { auth } from "./firebase";
import { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { encode as base64_encode } from 'base-64';
import Cookies from "js-cookie";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);

    const cookieName = "loggedUser";
    const cookieExpiration = "1";

    const loginUser = (email, password) => {
        setLoading(true);
        return signInWithEmailAndPassword(auth, email, password);
    };

    const logOut = () => {
        setLoading(true);
        return signOut(auth);
    };

    useEffect(() => {
        const unsubcribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser === null) {
                Cookies.remove(cookieName);
            } else {
                let encodedEmail = base64_encode(currentUser.email);
                Cookies.set(cookieName, encodedEmail, cookieExpiration);
            }
            setLoading(false);
        });

        return () => {
            unsubcribe();
        };
    }, []);

    const value = {
        loginUser,
        logOut,
        loading,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { AuthContext, AuthProvider };