import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { Link } from 'react-router-dom';

const items = [
    { name: 'Home', href: '/' },
    { name: 'Chi Siamo', href: '/chi-siamo' },
    { name: 'Servizi', href: '/servizi' },
    { name: 'Cerca', href: '/cerca' },
    { name: 'Contatti', href: '/contatti' },
]

const MenuMobile = () => {
    return (
        <div className="xl:hidden md:hidden">
            <Popover className="relative z-2000">
                <Popover.Button>
                    <div className='w-screen h-[40px] bg-site-grey'>
                        <div className='flex justify-center'>
                            <i className="icofont-navigation-menu text-3xl text-white"></i>
                        </div>
                    </div>
                </Popover.Button>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                >
                    <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
                        {({ close }) =>
                            <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                                <div className="p-4">
                                    {items.map((item) => (
                                        <div key={item.name} className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
                                            <div className='w-full h-full'>
                                                <Link onClick={() => close()} to={item.href} className="text-[15px] text-gray-900 block w-full h-full">
                                                    {item.name}
                                                </Link>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        }
                    </Popover.Panel>
                </Transition>
            </Popover>
        </div>
    );
};

export default MenuMobile;