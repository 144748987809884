
import Toast from './Toast';
import { useContext, useState } from 'react'
import { FirestoreContext } from './FirestoreProvider';

const LocationItem = ({ item }) => {

    const { deleteLocation, getLocations, updateLocation } = useContext(FirestoreContext);
    const [isError, setIsError] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);

    const deleteCurrentLocation = async () => {
        await deleteLocation(item.id)
            .then(() => {
                setShowToast(true);
                setIsError(false);
                setToastMessage("Località cancellata con successo")
                getLocations();
            })
            .catch(() => {
                setShowToast(true);
                setIsError(true);
                setToastMessage("Errore durante la cancellata")
            })
    }

    const updateCurrentLocation = async () => {
        await updateLocation(item.id, item.value)
        .then((res) => {
            setShowToast(true);
            setIsError(false);
            setToastMessage("Località modificata con successo")
            getLocations();
        }).catch((err) => {
            setShowToast(true);
            setIsError(true);
            setToastMessage("Errore durante la modifica della località.")
            console.log(err);
        });
    }

    const [currentLocation, setCurrentLocation] = useState("");
    const onChangeCurrentLocation = (e) => {
        item.value = e.target.value;
        setCurrentLocation(item.value);
    }

    const onCloseToast = () => {
        setShowToast(false);
    }

    return (
        <>
            {showToast &&
                <Toast isError={isError} message={toastMessage} duration={3000} whenClose={onCloseToast} />
            }
            <div className="w-xl flex flex-row mt-[10px]" key={item.key}>

                <input className="input-text w-[80%] mr-[5px]" type="text" value={item.value} onChange={onChangeCurrentLocation} placeholder="Modifica località" />

                <div className="w-[10%] mr-[10px]">
                    <button className='w-full btn-orange text-white font-bold pt-[5px] pb-[5px] pl-[10px] pr-[10px] rounded-[2px] text-[15px]' onClick={updateCurrentLocation} >Modifica</button>
                </div>

                <div className="w-[10%]">
                    <button className='w-full btn-orange text-white font-bold pt-[5px] pb-[5px] pl-[10px] pr-[10px] rounded-[2px] text-[15px]' onClick={deleteCurrentLocation}>Elimina</button>
                </div>
            </div>
        </>
    )
}

export default LocationItem