import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div>
            <div className='flex xl:flex-row md:flex-row bg-site-grey flex-col justify-center md:items-center pb-10'>
                <div className='flex flex-col text-site-txt-white items-start pe-10 ps-10 pt-10 pb-7 text-base'>
                    <p className='font-bold leading-7'>Immobiliare Carnazzola Clara</p>
                    <p className='leading-7'>P.Iva 00862930146</p>
                    <p className='leading-7'>CF CRNCLR69S62I829G</p>
                    <span className='leading-7 whitespace-pre-line'><Link to='/privacy'>Informativa sulla privacy e utilizzo dei cookie</Link></span>
                </div>
                <div className='flex flex-col pe-10 ps-10 text-site-txt-white items-start text-base'>
                    <div className='flex flex-row'>
                        <div className='pe-2'><i className="icofont-location-pin"></i></div>
                        <div>via Vanoni 33 - Caspoggio (SO)</div>
                    </div>
                    <div className='flex flex-row'>
                        <div className='pe-2'><i className="icofont-phone"></i></div>
                        <div>0342 45 24 91</div>
                    </div>
                    <div className='flex flex-row'>
                        <div className='pe-2'><i className="icofont-iphone"></i></div>
                        <div>347 85 28 235</div>
                    </div>
                    <div className='flex flex-row'>
                        <div className='pe-2'><i className="icofont-email"></i></div>
                        <div>info@immobiliarecarnazzola.com</div>
                    </div>
                </div>
            </div>
            <div className='xl:items-center md:items-center bg-site-dark-grey flex flex-col justify-center'>
                <div className='text-site-txt-white text-xs'>
                    © {new Date().getFullYear()} Immobiliare Carnazzola Clara
                </div>
                <div></div>
            </div>
        </div>
    )
}

export default Footer