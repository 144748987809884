import React from 'react'
import { Link } from 'react-router-dom'

const items = [
    { name: 'Home', href: '/' },
    { name: 'Chi Siamo', href: '/chi-siamo' },
    { name: 'Servizi', href: '/servizi' },
    { name: 'Cerca', href: '/cerca' },
    { name: 'Contatti', href: '/contatti' },
]


const MenuDesktop = () => {
    return (
        <div className='xl:flex md:flex hidden bg-site-grey w-screen h-[40px] justify-center'>
            <div className='flex flex-col xl:w-topbar-xl'>
                <div className='w-full h-full flex'>
                    {items.map(item => (
                        <Link key={item.name} to={item.href} className='flex items-center justify-center text-white font-bold w-[120px] p-[11px] text-[15px] hover:border-b-[5px] hover:pb-[7px] hover:border-b-site-dark-grey'>{item.name}</Link>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default MenuDesktop