import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import SearchBar from './SearchBar'
import { FirestoreContext } from './FirestoreProvider';

const Home = () => {

    const { getBox, getBuildings } = useContext(FirestoreContext);


    const [box1, setBox1] = useState(null);
    const [box2, setBox2] = useState(null);
    const [buildings1, setBuildings1] = useState(null);
    const [buildings2, setBuildings2] = useState(null);
    const [showB1, setShowB1] = useState(null);
    const [showB2, setShowB2] = useState(null);

    useEffect(() => {
        getBox()
            .then((res) => {
                setBox1(res[0]);
                setBox2(res[1]);
            });

        getBuildings(null, null, null, "box1")
            .then((res) => {
                setBuildings1(res);
                setShowB1([res[0], res[1]]);
            });

        getBuildings(null, null, null, "box2")
            .then((res) => {
                setBuildings2(res);
                setShowB2([res[0], res[1]]);
            });
    }, [])

    const next1 = () => {
        if (showB1.length === buildings1.length) {
            return;
        }
        let index = buildings1.findIndex(b => b.id === showB1[1].id);
        let app = [];
        if (index + 1 === buildings1.length) {
            app.push(showB1[1]);
            app.push(buildings1[0]);
        }
        else {
            app = [showB1[1], buildings1[index + 1]];
        }
        setShowB1(app);
    }

    const prev1 = () => {
        if (showB1.length === buildings1.length) {
            return;
        }
        let index = buildings1.findIndex(b => b.id === showB1[0].id);
        let app = [];
        if (index - 1 === -1) {
            app.push(buildings1[buildings1.length - 1]);
            app.push(showB1[0]);
        }
        else {
            app = [buildings1[index - 1], showB1[0]];
        }
        setShowB1(app);
    }

    const next2 = () => {
        if (showB2.length === buildings2.length) {
            return;
        }
        let index = buildings2.findIndex(b => b.id === showB2[1].id);
        let app = [];
        if (index + 1 === buildings2.length) {
            app.push(showB2[1]);
            app.push(buildings2[0]);
        }
        else {
            app = [showB2[1], buildings2[index + 1]];
        }
        setShowB2(app);
    }

    const prev2 = () => {
        if (showB2.length === buildings2.length) {
            return;
        }
        let index = buildings2.findIndex(b => b.id === showB2[0].id);
        let app = [];
        if (index - 1 === -1) {
            app.push(buildings2[buildings2.length - 1]);
            app.push(showB2[0]);
        }
        else {
            app = [buildings2[index - 1], showB2[0]];
        }
        setShowB2(app);
    }


    return (
        <div>
            <div className='flex flex-col items-center text-claim xl:text-2xl md:text-2xl text-xl whitespace-break-spaces pb-5'>
                <div className='p-5 '>
                    <i className="icofont-quote-left text-site-light-grey top-[-15px] left-[-5px] relative text-xs"></i>Oggi si conosce il prezzo di tutte le cose e il valore di nessuna<i className="icofont-quote-right relative text-site-light-grey top-[15px] left-[5px] text-xs"></i>
                </div>
                <div className='xl:w-[45%] md:w-full w-[85%] flex justify-end mt-[-10px]'>
                    <p className='pe-3 text-site-orange font-bold xl:text-xl md:text-lg text-base'>Oscar Wilde</p>
                </div>
            </div>
            <div className='flex xl:flex-row md:flex-col flex-col justify-center items-center w-full mb-[30px]'>
                <div className='flex flex-col xl:h-[840px]'>
                    <div className='flex xl:flex-row md:flex-row flex-col justify-center items-center'>
                        <div className='md:w-[350px] w-[300px] border-site-light-grey border p-[10px] mt-10px md:mr-10px'>
                            <div className='flex flex-col'>
                                <Link to='/cerca' className='p-5 bg-site-light-grey2 font-bold text-xl text-center text-claim mb-[20px] base-transition hover:bg-site-red hover:text-white'
                                    state={{ localita: undefined, contract: 'Vendita', tipology: undefined, price: undefined, lease: undefined, runSearch: true, itemList: [] }}
                                >
                                    IMMOBILI IN VENDITA
                                </Link>
                                <div>
                                    <img src='citta.jpg' alt='immobili in vendita' />
                                </div>
                            </div>
                        </div>
                        <div className='md:w-[350px] w-[300px] border-site-light-grey border p-[10px] mt-10px xl:ml-10px xl:mr-10px'>
                            <div className='flex flex-col'>
                                <Link to='/cerca' className='p-5 bg-site-light-grey2 font-bold text-xl text-center text-claim mb-[20px] base-transition hover:bg-site-orange hover:text-white'
                                    state={{ localita: undefined, contract: 'Locazione', tipology: undefined, price: undefined, lease: undefined, runSearch: true, itemList: [] }}
                                >
                                    IMMOBILI IN LOCAZIONE
                                </Link>
                                <div>
                                    <img src='montagna.jpg' alt='immobili in locazione' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex xl:flex-row md:flex-row flex-col justify-center items-center'>
                        {
                            box1 !== null &&
                            <div className='md:w-[350px] w-[300px] mt-20px md:mr-10px'>
                                <div className='mb-20px flex flex-col items-center'>
                                    <div className='bg-site-light-grey text-white font-bold p-5 text-center w-full min-h-[90px] max-h-[90px] flex'>
                                        <div className='w-full flex items-center justify-center'>{box1.name}</div>
                                    </div>
                                    <div className="triangle"></div>
                                </div>
                                {
                                    (showB1 !== null && buildings1.length > 0) &&
                                    <>
                                        <div className='flex md:flex-row flex-col w-full'>

                                            {showB1.map((b, index) => {
                                                if (b ?? false) {
                                                    let img = { url: "" };
                                                    if (b.images ?? false) {
                                                        let cover = b.images.find(img => img.cover === true);
                                                        img = b.images[0];
                                                        if (cover !== null && cover !== undefined) {
                                                            img = cover;
                                                        }
                                                    }
                                                    return (
                                                        <div key={index} className='md:w-[200px] w-[calc(100% - 10px)] h-[280px] border border-site-grey p-10px md:mr-[5px] mb-[20px]'>
                                                            <Link to={'/annuncio/' + b.idAnnuncio}>
                                                                <div className='w-[100%] h-[200px] overflow-hidden'>
                                                                    <img src={img.url} alt='foto' className='w-[100%] h-[100%]' />
                                                                </div>
                                                                <div className='pt-10px pb-10px text-claim font-bold uppercase text-base text-center'>
                                                                    <span>{b.city.value}</span>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    )
                                                }
                                            })
                                            }
                                        </div>
                                        <div className='flex flex-row w-full justify-center md:mt-0 mt-[20px]'>
                                            <div onClick={prev1} className='cursor-pointer w-[30px] h-[30px] bg-site-light-grey leading-[30px] text-center text-[25px] text-white mr-[10px]'>
                                                <i className="icofont-simple-left"></i>
                                            </div>
                                            <div onClick={next1} className='cursor-pointer w-[30px] h-[30px] bg-site-light-grey leading-[30px] text-center text-[25px] text-white ml-[10px]'>
                                                <i className="icofont-simple-right"></i>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        }
                        {
                            box2 !== null &&
                            <div className='md:w-[350px] w-[300px] mt-20px xl:ml-10px xl:mr-10px'>
                                <div className='mb-20px flex flex-col items-center'>
                                    <div className='bg-site-light-grey text-white font-bold p-5 text-center w-full min-h-[90px] max-h-[90px] flex'>
                                        <div className='w-full flex items-center justify-center'>{box2.name}</div>
                                    </div>
                                    <div className="triangle"></div>
                                </div>
                                {
                                    (showB2 !== null && buildings2.length > 0) &&
                                    <>
                                        <div className='flex md:flex-row flex-col w-full'>
                                            {showB2.map((b, index) => {
                                                if (b ?? false) {
                                                    let img = { url: "" };
                                                    if (b ?? false) {
                                                        img = b.images[0];
                                                        let cover = b.images.find(img => img.cover === true);
                                                        if (cover !== null && cover !== undefined) {
                                                            img = cover;
                                                        }
                                                    }
                                                    return (
                                                        <div key={index} className='md:w-[200px] w-[calc(100% - 10px)] h-[280px] border border-site-grey p-10px md:mr-[5px] mb-[20px]'>
                                                            <Link to={'/annuncio/' + b.idAnnuncio}>
                                                                <div className='w-[100%] h-[200px] overflow-hidden'>
                                                                    <img src={img.url} alt='foto' className='w-[100%] h-[100%]' />
                                                                </div>
                                                                <div className='pt-10px pb-10px text-claim font-bold uppercase text-base text-center'>
                                                                    <span>{b.city.value}</span>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    )
                                                }
                                            })
                                            }
                                        </div>
                                        <div className='flex flex-row w-full justify-center md:mt-0 mt-[20px]'>
                                            <div onClick={prev2} className='cursor-pointer w-[30px] h-[30px] bg-site-light-grey leading-[30px] text-center text-[25px] text-white mr-[10px]'>
                                                <i className="icofont-simple-left"></i>
                                            </div>
                                            <div onClick={next2} className='cursor-pointer w-[30px] h-[30px] bg-site-light-grey leading-[30px] text-center text-[25px] text-white ml-[10px]'>
                                                <i className="icofont-simple-right"></i>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        }
                    </div>
                </div>
                <SearchBar />
            </div>
        </div >
    )
}

export default Home;