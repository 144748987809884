const flattenArray = (array, parentID) => {
    return array.sort((a, b) => a.order - b.order).reduce((acc, obj) => {
        const { children, ...rest } = obj;
        if (parentID) {
            rest.parentID = parentID;
            rest.value = "-" + rest.value;
        }
        acc.push(rest);
        if (children) {
            acc = acc.concat(flattenArray(children, rest.id));
        }

        return acc;
    }, []);
}

const isNullOrWhitespace = (value) => {
    return !value?.toString().trim()
}

const createAdvertisementID = (root, total) => {
    return (root += String(total).padStart(4, '0'));
}

const formatCurrency = (value) => {
    let formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });

    return formatter.format(value);
}

const CONTRACTS = [
    {
        key: "vendita",
        value: "Vendita"
    },
    {
        key: "locazione",
        value: "Locazione"
    }
]

const PRICE_RANGE = [
    {
        key: "1",
        value:"Fino a 50.000"
    },
    {
        key: "2",
        value:"Da 50.000 a 100.000"
    },
    {
        key: "3",
        value:"Da 100.000 a 150.000"
    },
    {
        key: "4",
        value:"Da 150.000 a 200.000"
    },{
        key: "5",
        value:"Da 200.000"
    }
]

const LEASE_RANGE = [
    {
        key:"1",
        value: "Stagione estiva"
    },
    {
        key:"2",
        value: "Stagione invernale"
    },
    {
        key:"3",
        value: "Luglio"
    },
    {
        key:"4",
        value: "Agosto"
    },
    {
        key:"5",
        value: "Settimane"
    },
    {
        key:"6",
        value: "Capodanno"
    },
    {
        key:"7",
        value: "Annuale"
    }
]

const BOX1 = "box1";
const BOX2 = "box2";

const BASE_LINK = "https://www.immobiliarecarnazzola.com/annuncio/";
//const BASE_LINK = "https://immobiliare-carnazzola.vercel.app/annuncio/";

const LS_LOCATION = 'location';
const LS_CONTRACT = 'contract';
const LS_TIPOLOGY = 'tipology';
const LS_PRICERANGE = 'pricerange';
const LS_LEASERANGE = 'leaserange';

export { flattenArray, isNullOrWhitespace, createAdvertisementID, formatCurrency, CONTRACTS, PRICE_RANGE, LEASE_RANGE, BOX1, BOX2, BASE_LINK, LS_LOCATION, LS_CONTRACT, LS_TIPOLOGY, LS_PRICERANGE, LS_LEASERANGE };
