import { useEffect, useState } from "react";

const Slider = ({ showSliderCallBask, index, images }) => {

    const [currentImage, setCurrentImage] = useState({ src: "" });
    const [currentIndex, setCurrentIndex] = useState(-1);

    useEffect(() => {
        let appImage = images.at(index);
        setCurrentImage(appImage);
        setCurrentIndex(index);
    }, [])

    const nextImage = () => {
        let app = currentIndex + 1;
        if (currentIndex === images.length - 1) {
            app = 0;
        }

        setCurrentIndex(app);
        changeCurrentImage(app);
    }

    const prevImage = () => {
        let app = currentIndex - 1;
        if (currentIndex === 0) {
            app = images.length - 1;
        }

        setCurrentIndex(app);
        changeCurrentImage(app);
    }

    const changeCurrentImage = (index) => {
        let appImage = images.at(index);
        setCurrentImage(appImage);
    }

    return (
        <div className="lightbox">
            <i className="fa fa-arrow-circle-o-left" aria-hidden="true" onClick={prevImage}></i>
            <i className="fa fa-times-circle-o" aria-hidden="true" onClick={showSliderCallBask}></i>
            <img src={currentImage.url} />
            <i className="fa fa-arrow-circle-o-right" aria-hidden="true" onClick={nextImage}></i>
        </div>
    )
}

export default Slider;