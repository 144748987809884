import React from 'react'

const items = [
    { name: 'Immobili', href: '/immobili' },
    { name: 'News', href: '/immobili' },
    { name: 'Località', href: '/localita' },
    { name: 'Tipologie', href: '/tipologie' },
    { name: 'Box', href: '/box' },
    { name: 'Esci', href: '/logout' },
]


const MenuAdminDesktop = () => {
    return (
        <div className='xl:flex md:flex hidden bg-site-orange w-screen h-[40px] justify-center'>
            <div className='flex flex-col xl:w-topbar-xl'>
                <div className='w-full h-full flex'>
                    {items.map(item => (
                        <a key={item.name} href={item.href} className='flex items-center justify-center text-white font-bold w-[120px] p-[11px] text-[15px] hover:border-b-[5px] hover:pb-[7px] hover:border-b-site-lite-orange'>{item.name}</a>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default MenuAdminDesktop;