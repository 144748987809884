import React from 'react'
import Search from './Search'
import { Link } from 'react-router-dom'

const SearchBar = () => {
    return (
        <div className='flex xl:mt-[20px] md:mt-[20px] mt-[20px] xl:w-[300px] md:w-[750px] w-[350px] h-[840px]'>
            <div className='bg-site-light-grey2 ml-[20px] mr-[20px] pt-[5px] pb-[5px]' >
                <Search searchPage={false} searchCallback={null}/>
                <div className='font-bold text-2xl text-claim border-t-[3px] border-t-site-lite-orange border-b-[3px] border-b-site-lite-orange m-[10px]'>
                    Segnalaci un immobile
                </div>
                <div className='flex flex-row m-[10px] text-[15px] text-claim'>
                    <div className='flex flex-col justify-center mr-[20px]'>
                        <i className="icofont-home text-[40px]"></i>
                    </div>
                    <div className='text-justify'>
                        <p>Cerchiamo, per nostra clientela, immobili da vendere e/o da affittare, valutazione gratuita con verifica valore di mercato</p>
                        <p className='text-site-orange font-bold'>Chiama subito il 347 85 28 235</p>
                    </div>
                </div>
                <div className='font-bold text-2xl text-claim border-t-[3px] border-t-site-lite-orange border-b-[3px] border-b-site-lite-orange m-[10px]'>
                    Cerchiamo per te
                </div>
                <div className='flex flex-row m-[10px] text-[15px] text-claim'>
                    <div className='flex flex-col justify-center mr-[20px]'>
                        <i className="icofont-search-property text-[40px]"></i>
                    </div>
                    <div className='text-justify'>
                        <p>Cerchiamo per te l’immobile che desideri non esitare a contattarci</p>
                        <p className='text-site-orange font-bold'>Chiama subito il 347 85 28 235</p>
                    </div>
                </div>
                <div className='font-bold text-2xl text-claim border-t-[3px] border-t-site-lite-orange border-b-[3px] border-b-site-lite-orange m-[10px]'>
                    Newsletter
                </div>
                <div className='w-full'>
                    <input className='ml-[10px] mr-[10px]' type='email' />
                </div>
                <div className='h-auto m-[10px]'>
                    <Link to='/cerca' className='btn-orange text-white font-bold pt-[5px] pb-[5px] pl-[10px] pr-[10px] rounded-[2px] text-[15px]'>ISCRIVITI</Link>
                </div>
            </div >
        </div >
    )
}

export default SearchBar