import { useContext, useEffect, useState } from "react";
import { FirestoreContext } from "./FirestoreProvider";

const BoxItem = ({ box, callbackShowToast }) => {

    const { updateBox } = useContext(FirestoreContext);
    const [currentBoxName, setCurrentBoxName] = useState({});

    const handleChange = (e) => {
        setCurrentBoxName(e.target.value);
    }

    const handleSave = async () => {
        await updateBox(box.id, currentBoxName)
        .then(() => {
            callbackShowToast(false, "Box salvato con successo");
        })
        .catch(() => {
            callbackShowToast(true, "Errore salvataggio box");
        })
    }

    useEffect(() => {
        setCurrentBoxName(box.name)
    }, []);

    return (
        <div className="w-[1200px] mb-4">
            <div className="flex flex-raw justify-center">
                <input className="input-text w-[85%] mr-[5px]" type="text" placeholder="Nome Box" value={currentBoxName} onChange={handleChange} />
                <div className="w-[10%]">
                    <button className='w-full btn-orange text-white font-bold pt-[5px] pb-[5px] pl-[10px] pr-[10px] rounded-[2px] text-[15px]' onClick={handleSave}>Salva</button>
                </div>
            </div>
        </div>
    )

}

export default BoxItem;