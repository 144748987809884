import { useEffect, useState } from "react";
import { formatCurrency, isNullOrWhitespace } from "./CommonFunction";
import { useNavigate } from "react-router-dom";

const CercaItem = ({ item, localita, contract, tipology, price, lease, itemList }) => {

    const navigate = useNavigate();

    const [currentItem, setCurrenItem] = useState(null);
    const [prezzo, setPrezzo] = useState("");
    const [cover, setCover] = useState("");

    useEffect(() => {
        if (item.prezzo.visible && !isNullOrWhitespace(item.prezzo.value)) {
            setPrezzo(formatCurrency(item.prezzo.value));
        }

        if (item.images !== null && item.images !== undefined && item.images.length > 0) {
            var image = item.images.filter(img => img.cover === true);
            if (!!image && image.length > 0) {
                setCover(image[0])
            } else {
                setCover(item.images[0]);
            }
        }

        setCurrenItem(item);
    }, []);

    const showDetails = () => {
        navigate("/annuncio/" + currentItem.idAnnuncio, {
            state: {
                localita: localita, contract: contract, tipology: tipology, price: price, lease: lease, itemList: itemList
            }
        });
    }

    return (
        <>
            {(currentItem ?? false) &&
                <div key={currentItem.id} className="flex flex-col mt-[10px] mb-[10px] cursor-pointer search-item" name="result-item" onClick={showDetails}>
                    <div className="flex xl:flex-row md:flex-row flex-col">
                        <div className="xl:w-[30%] md:w-[30%] w-full relative">
                            {
                                (currentItem.contract.value === "Locazione") &&
                                <span className="search-label-locazione">locazione</span>
                            }
                            {
                                (currentItem.contract.value === "Vendita") &&
                                <span className="search-label-vendita">vendita</span>
                            }
                            <img className="img-search" alt='' src={cover.url} />
                        </div>
                        <div className="xl:w-[70%] md:w-[70%] w-full xl:mt-0 md:mt-0 mt-[10px]">
                            <div className="flex flex-col xl:pl-[10px] md:pl-[10px] pl-0">
                                <div className="flex flex-row w-full text-site-dark-grey xl:text-base md:text-base text-sm pb-[10px]">
                                    <div className="w-[70%] text-start">
                                        {currentItem.tipologia.value[0]}, {currentItem.city.value}
                                    </div>
                                    {
                                        (currentItem.prezzo.visible && isNullOrWhitespace(currentItem.prezzo.value) === false) &&
                                        <div className="w-[30%] text-end pr-[5px]">
                                            {prezzo}
                                        </div>
                                    }
                                </div>
                                <hr className="border-site-dark-grey" />
                                <div className="pt-[10px] description-result text-xs text-justify" dangerouslySetInnerHTML={{ __html: currentItem.descrizione.value.replace('\n', '<br />') }}>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="border-site-orange mt-[20px]" />
                </div>
            }
        </>
    )

}

export default CercaItem;