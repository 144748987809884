import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from '../AuthProvider';

const Logout = () => {
    const { logOut } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        logOut();
        navigate("/");
    }, [logOut, navigate])

    return (<div></div>)
}

export default Logout