import React from 'react'
import SearchBar from './SearchBar'

const Servizi = () => {
    return (
        <div className='flex  xl:flex-row flex-col justify-center items-center w-full mb-[30px] xl:items-start xl:mt-[30px]'>
            <div className='flex flex-col xl:mt-0 md:mt-[20px] mt-[20px] xl:w-[800px] md:w-[750px] w-[350px]'>
                <div className='font-bold ml-[20px] mr-[20px] text-xl text-claim border-t-[3px] border-t-site-lite-orange border-b-[3px] border-b-site-lite-orange m-[10px]'>
                    I servizi offerti da Immobiliare Carnazzola Clara
                </div>
                <div className='ml-[20px] mr-[20px] pt-[5px] pb-[5px]' >
                    <div className='flex flex-row m-[10px] text-[15px] text-claim items-center'>
                        <div className='servizi-icon'>
                            <i className="icofont-euro ml-[-7px]"></i>
                        </div>
                        <div className='ml-[20px] text-claim text-base text-justify'>
                            <p className='font-bold text-base mb-[5px]'>Valutazione gratuita con verifica valore di mercato</p>
                            <p>Cerchiamo, per nostra clientela, immobili da vendere e/o da affittare, valutazione gratuita con verifica valore di mercato</p>
                        </div>
                    </div>
                </div>
                <div className='ml-[20px] mr-[20px] pt-[5px] pb-[5px]' >
                    <div className='flex flex-row m-[10px] text-[15px] text-claim items-center'>
                        <div className='servizi-icon'>
                            <i className="icofont-search-property"></i>
                        </div>
                        <div className='ml-[20px] text-claim text-base text-justify'>
                            <p className='font-bold text-base mb-[5px]'>Cerchiamo per te</p>
                            <p>Cerchiamo per te l’immobile che desideri non esitare a contattarci</p>
                        </div>
                    </div>
                </div>
                <div className='ml-[20px] mr-[20px] pt-[5px] pb-[5px]' >
                    <div className='flex flex-row m-[10px] text-[15px] text-claim items-center'>
                        <div className='servizi-icon'>
                            <i className="icofont-building-alt"></i>
                        </div>
                        <div className='ml-[20px] text-claim text-base text-justify'>
                            <p className='font-bold text-base mb-[5px]'>Amministriamo condomini</p>
                            <p>Amministratore Condominiale</p>
                            <p>Socio A.N.AMM.I. n. S494</p>
                        </div>
                    </div>
                </div>
                <div className='ml-[20px] mr-[20px] pt-[5px] pb-[5px]' >
                    <div className='flex flex-row m-[10px] text-[15px] text-claim items-center'>
                        <div className='servizi-icon'>
                            <i className="icofont-paper"></i>
                        </div>
                        <div className='ml-[20px] text-claim text-base text-justify'>
                            <p className='font-bold text-base mb-[5px]'>Registrazione contratti di locazione</p>
                            <p>Registriamo on line contratti di locazione anche ad esterni</p>
                        </div>
                    </div>
                </div>
            </div>
            <SearchBar />
        </div>
    )
}

export default Servizi