import { useContext, useEffect, useState } from 'react'
import { FirestoreContext } from './FirestoreProvider';

import { v4 as uuidv4 } from 'uuid'
import MenuAdminDesktop from "./MenuAdminDesktop";
import Toast from './Toast';
import TypologyTreeParent from './TypologyTreeParent';

const Typologies = () => {
    const { typologies, addParentTypology, retrieveTypologies } = useContext(FirestoreContext);
    const [currentParent, setCurrentParent] = useState("");
    const [typologyList, setTypologyList] = useState([]);
    const [currentOrder, setCurrentOrder] = useState(0);
    const [isError, setIsError] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);

    const handleChangeCurrentParent = (e) => {
        setCurrentParent(e.target.value);
    }

    const handleChangeCurentOrder = (e) => {
        setCurrentOrder(e.target.value);
    }

    const addTypology = async () => {
        const typology = {
            key: uuidv4(),
            value: currentParent,
            order: currentOrder
        };

        await addParentTypology(typology)
            .then(() => {
                setCurrentParent("");
                setCurrentOrder(0);
                setToastMessage("Inserimento avvenuto con successo");
                setIsError(false);
                setShowToast(true);
                retrieveTypologies();
            }).catch(() => {
                setToastMessage("Errore durrante il salvataggio");
                setIsError(true);
                setShowToast(true);
                setCurrentParent("");
                setCurrentOrder(0);
            });
    }

    const onCloseToast = () => {
        setShowToast(false);
    }

    useEffect(() => {
        retrieveTypologies();
    }, []);

    useEffect(() => {
        if (typologies ?? false) {
            const sorted = typologies.sort((a, b) => a.order - b.order);
            setTypologyList(sorted);
        }
    }, [typologies])

    return (
        <div>
            {showToast &&
                <Toast isError={isError} message={toastMessage} duration={3000} whenClose={onCloseToast} />
            }
            <MenuAdminDesktop />
            <div className="w-full flex flex-col items-center justify-center">
                <div className='w-xl font-bold text-xl text-claim text-center border-t-[3px] border-t-site-lite-orange border-b-[3px] border-b-site-lite-orange mt-[10px] mb-[10px]'>
                    Tipologie
                </div>
                <div className="w-xl flex flex-row">
                    {/* <div className="w-[100%] pr-[10px]"> */}
                    <input className="input-text w-[85%] mr-[5px]" type="text" value={currentParent} placeholder="Aggiungi categoria" onChange={handleChangeCurrentParent} />
                    <input className="input-text w-[5%] mr-[5px]" type="number" min={0} value={currentOrder} placeholder="Aggiungi categoria" onChange={handleChangeCurentOrder} />
                    {/* </div> */}
                    <div className="w-[10%]">
                        <button className='w-full btn-orange text-white font-bold pt-[5px] pb-[5px] pl-[10px] pr-[10px] rounded-[2px] text-[15px]' onClick={addTypology}>Aggiungi</button>
                    </div>
                </div>
                <div className="w-xl mt-[20px]">
                    {typologyList.map((item, index) => {
                        return (
                            <TypologyTreeParent key={index} item={item} index={index} />
                        )
                    })}
                </div>
            </div>
        </div>
    );
}

export default Typologies;