import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";

const PrivateRoute = ({ children }) => {
    
    const cookieName = "loggedUser";
    let cookie = Cookies.get(cookieName);

    if (cookie) {
        return children;
    }

    return <Navigate to="/login" />;
};



PrivateRoute.propTypes = {
    children: PropTypes.node,
};

export default PrivateRoute;