import React, { useEffect, useContext, useState } from 'react'
import MenuAdminDesktop from "./MenuAdminDesktop";
import { FirestoreContext } from './FirestoreProvider';
import LocationItem from './LocationItem';
import Toast from './Toast';
import { v4 as uuidv4 } from 'uuid'
import { isNullOrWhitespace } from './CommonFunction';

const Location = () => {

    const { locations, getLocations, addLocation } = useContext(FirestoreContext);
    const [locationList, setLocationList] = useState([]);
    const [currentLocation, setCurrentLocation] = useState("");
    const [isError, setIsError] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);

    const handleChangeCurrentLocation = (e) => {
        setCurrentLocation(e.target.value)
    }

    const addCurrentLocation = async () => {
        if (isNullOrWhitespace(currentLocation)) {
            setToastMessage("Nome località nullo o vuoto");
            setIsError(true);
            setShowToast(true);
            return;
        }

        const location = {
            key: uuidv4(),
            value: currentLocation
        };

        await addLocation(location)
            .then(() => {
                setCurrentLocation("");
                setToastMessage("Inserimento avvenuto con successo");
                setIsError(false);
                setShowToast(true);
                getLocations();
            }).catch((err) => {
                setToastMessage("Errore durrante il salvataggio");
                setIsError(true);
                setShowToast(true);
                setCurrentLocation("");
                console.log(err);
            });
    }

    const onCloseToast = () => {
        setShowToast(false);
    }

    useEffect(() => {
        getLocations();
    }, [])

    useEffect(() => {
        const naturalCollator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

        const sorted = locations.sort((a, b) => naturalCollator.compare(a.value, b.value));
        setLocationList(sorted);
    }, [locations])

    return (
        <>
            {showToast &&
                <Toast isError={isError} message={toastMessage} duration={3000} whenClose={onCloseToast} />
            }
            <MenuAdminDesktop />
            <div className="w-full flex flex-col items-center justify-center mb-[10px]">
                <div className='w-xl font-bold text-xl text-claim text-center border-t-[3px] border-t-site-lite-orange border-b-[3px] border-b-site-lite-orange mt-[10px] mb-[10px]'>
                    Località
                </div>
                <div className="w-xl flex flex-row">

                    <input className="input-text w-[90%] mr-[5px]" type="text" value={currentLocation} placeholder="Aggiungi località" onChange={handleChangeCurrentLocation} />

                    <div className="w-[10%]">
                        <button className='w-full btn-orange text-white font-bold pt-[5px] pb-[5px] pl-[10px] pr-[10px] rounded-[2px] text-[15px]' onClick={addCurrentLocation}>Aggiungi</button>
                    </div>
                </div>
                {locationList.map((location) => {
                    return (
                        <LocationItem item={location} key={location.id} />
                    )
                })}
            </div>
        </>
    )
}

export default Location