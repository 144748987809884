import React, { Component } from 'react'
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';

class PopoverPolicy extends Component {

    cookie

    cookieName = "immobiliarecarnazzolapolicy";
    cookieValue = "hide"
    cookieExpiration = 10000

    constructor() {
        super()
        var val = Cookies.get(this.cookieName) ?? 'show';
        this.state = {
            show: val
        }
    }

    policyOk = () => {
        Cookies.set(this.cookieName, this.cookieValue, this.cookieExpiration)
        this.setState(() => ({
            show: this.cookieValue
        }));
    }

    render() {
        return (
            <div className='fixed  w-full top-0 left-0 z-2000'>
                {this.state.show === 'show' && (
                    <div className='privacy-cnt w-screen flex flex-col'>
                        <h2 className='text-2xl font-bold mt-[10px] mb-[5px]'>Coockie Policy</h2>
                        <div className='text-sm'>Questo sito utilizza cookie necessari al suo funzionamento ed utili alle finalità illustrate nella <Link to='/privacy' className='underline'>cookie policy</Link>.</div>
                        <div className='text-sm'>Chiudendo questo banner, scorrendo la pagina o proseguendo la navigazione sul sito acconsenti all'utilizzo dei cookie</div>
                        <div className='h-auto mt-[10px] mb-[10px]'>
                            <span onClick={this.policyOk} className='cursor-pointer btn-orange text-white font-bold pt-[5px] pb-[5px] pl-[10px] pr-[10px] rounded-[2px] text-[15px]'>OK</span>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}


export default PopoverPolicy